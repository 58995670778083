import React, {useContext, useState} from 'react';
import {ThemeContext} from "../../context/ThemeContext";
import {LangContext} from "../../context/LangContext";
import {makeStyles} from "@material-ui/styles";
import ServiceIcon from "../ServiceIcon";
import ServiceCardCover from "./ServiceCardCover";
import {colorFromTheme, parseBrandName} from "../helper"

const useStyle = makeStyles({
    ServiceCard: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flexStart",
        width: 300,
        height: 400,
        background: theme => theme.name === "dark" ? theme.backgroundElevation : theme.white,
        boxShadow: theme => theme.headerShadow,
        borderRadius: 15,
        opacity: 1,
        padding: 30,
        position: "relative",
        margin: 20,
        overflow: "hidden",

        "& h4": {
            font: " normal 400 normal 24px/35px Poppins",
            color: theme => theme.name === "dark" ? theme.tint : theme.shade,
            width: "100%",
            marginTop: 30,
            marginBottom: 0,
        },

        "& p": {
            font: "normal 400 normal 16px/25px Poppins",
            color: theme => theme.name === "dark" ? theme.tint : theme.shade,
            marginTop: 20,
        }

    },



});



function ServiceCard(props) {

    const {theme, toggleTheme} = useContext(ThemeContext)
    const {lang, toggleLang} = useContext(LangContext)

    const classes = useStyle({...theme, lang});

    const [coverVisible, setCoverVisible] = useState(false);

    return (
        <div className={classes.ServiceCard}>

            <ServiceIcon pic={props.service.icon_url} width={"75px"} height={"75px"} color1={colorFromTheme(1, props.service.gradient_type, theme)} color2={colorFromTheme(2,props.service.gradient_type, theme)}/>

            <h4 dangerouslySetInnerHTML={lang === "ar" ? {__html: parseBrandName(props.service.name_ar)} : {__html: parseBrandName(props.service.name_en)}}/>


            <p>
                {lang === "ar" ? props.service.description_ar : props.service.description_en}
            </p>

            <div style={{cursor: "pointer", position: "absolute", bottom: 20}}>
                <div onClick={()=>{setCoverVisible(true)}}>
                    pull it
                </div>
            </div>


            <ServiceCardCover push={()=>{setCoverVisible(false)}} service={props.service} lang={lang} coverVisible={coverVisible} />

        </div>
    );
}

export default ServiceCard;
