import React, {Component, useContext} from 'react'
import "./ServiceCardCover.css"
import {PrimaryButton} from "../../StyledComponents";


class ServiceCardCover extends Component {


    pushCover = () => {
        this.props.push();
    }


    render() {
        return (

            <div className="Service-card-cover" style={this.props.coverVisible ? {top: 0} : {}}>
                <img id="cover-image" alt="" src={this.props.service.image_url}/>

                <div id="cover-text-holder">
                    <h3>
                        {this.props.lang === "ar" ? this.props.service.back_question_ar : this.props.service.back_question_en}
                    </h3>

                    <p>
                        {this.props.lang === "ar" ? this.props.service.back_answer_ar : this.props.service.back_answer_en}
                    </p>

                    <h3>
                        {this.props.lang === "ar" ? "مزايانا" : "Our advantages" }
                    </h3>

                    <ul>

                        <li> {this.props.lang === "ar" ? this.props.service.advantage1_ar : this.props.service.advantage1_en} </li>


                        {this.props.service.advantage2_en ? <li> {this.props.lang === "ar" ? this.props.service.advantage2_ar : this.props.service.advantage2_en} </li> : ""}


                        {this.props.service.advantage3_en ? <li> {this.props.lang === "ar" ? this.props.service.advantage3_ar : this.props.service.advantage3_en} </li> : ""}

                    </ul>

                    <div id="cover-actions">


                        <div onClick={this.pushCover} style={{cursor: "pointer"}}>
                            push it
                        </div>


                        <PrimaryButton>
                            Contact us
                        </PrimaryButton>
                    </div>


                </div>

            </div>
        );
    }

}

export default ServiceCardCover;
