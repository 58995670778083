import React,{createContext, useState} from "react";

export const LangContext = createContext();


export function LangProvider(props){

    const [lang, setLang] = useState(localStorage.getItem('lang') ? "ar" : "en");

    function toggleLang(){
        if (lang === "ar"){
            setLang("en");
            localStorage.setItem("lang", "")
        }else{
            setLang("ar");
            localStorage.setItem("lang", "1")
        }
    }

    return (
        <LangContext.Provider value={{lang, toggleLang}}>
            {props.children}
        </LangContext.Provider>
    )

}
