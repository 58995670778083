import React, {useContext} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../../context/ThemeContext";
import {LangContext} from "../../context/LangContext";
import {PrimaryButton, SecondaryButton} from "../../StyledComponents";

const useStyle = makeStyles({
    "TextSection":{
        width: 560,
        display: "flex",
        flexDirection: "column"
    },

    '@media screen and (max-width: 720px)': {
        "TextSection": {
            width: "unset",
            padding: 40
        },

        "buttonsHolder": {

            flexDirection: "column",
            alignItems: "center",

            '& button': {
                marginRight: 0,
                marginTop: 20,
                width: 200
            }
        }
    },

    "header":{
        font: "normal normal 600 60px/90px Poppins",
        color: theme => theme.onPrimary,
        margin: 0,
        marginBottom: 20
    },

    "subHeader":{
        font: "normal normal 500 32px/48px Poppins",
        color: theme => theme.onPrimary,
        margin: 0,
        marginBottom: 10,

    },

    "paragraph":{
        font: "normal normal 400 16px/25px Poppins",
        color: theme => theme.onPrimary,
        margin: 0,
        marginBottom: 10
    },

    "numbersHolder":{
        display: "flex",
        margin: 0,
        marginBottom: 16,
        flexWrap: "wrap",

        '& a':{
            marginRight: theme => theme.lang === "ar" ? 0 : 64,
            marginLeft: theme => theme.lang === "ar" ? 64 : 0,
            font: "normal normal 500 24px/35px Poppins",
            color: theme => theme.onPrimary,
        }
    },

    "buttonsHolder":{
        display: "flex",
        flexWrap: "wrap",

        '& button':{
            marginRight: 5
        }
    },




})



function TextSection(props) {

    const {theme, toggleTheme} = useContext(ThemeContext)
    const {lang, toggleLang} = useContext(LangContext)


    const classes = useStyle({...theme, lang});

    let text;

    if (lang === "ar") {
        text = {
            header: "ويلام تيك",
            subHeader: "تصميم, تطوير وفلوس كتير",
            paragraph: "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.",
            buttonPrimary: "تواصل معنا",
            buttonOutline: "أعرف عنا اكثر"
        }

    } else {
        text = {
            header: "Whelam Tech",
            subHeader: "Design, Develop and Success",
            paragraph: "But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account.",
            buttonPrimary: "let's talk",
            buttonOutline: "Know how we work"
        }
    }

    return (
        <div className={classes.TextSection}>

            <h1 className={classes.header}>
                {text.header}
            </h1>

            <h3 className={classes.subHeader}>
                {text.subHeader}
            </h3>

            <p className={classes.paragraph}>
                {text.paragraph}
            </p>


            <div className={classes.numbersHolder}>
                <a href={"tel:+201553523930"}>
                    +201553523930
                </a>

                <a href={"tel:+201553898280"}>
                    +201553898280
                </a>
            </div>


            <div className={classes.buttonsHolder}>
                <PrimaryButton theme={{...theme, lang}}>{text.buttonPrimary}</PrimaryButton>
                <SecondaryButton theme={{...theme, lang}}>{text.buttonOutline}</SecondaryButton>
            </div>

        </div>
    );
}

export default TextSection;
