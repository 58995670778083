import React, {useState, useContext} from 'react';
import twitter from "../../assets/svgs/twitter.svg"
import insta from "../../assets/svgs/insta.svg"
import facebook from "../../assets/svgs/facebook.svg"
import twitterHover from "../../assets/svgs/twitterHover.svg"
import instaHover from "../../assets/svgs/instaHover.svg"
import facebookHover from "../../assets/svgs/facebookHover.svg"
import {ThemeContext} from "../../context/ThemeContext";
import {LangContext} from "../../context/LangContext";
import {makeStyles} from "@material-ui/styles";


const useStyle = makeStyles({
    "SocialMediaActions":{
        position: "absolute",
        bottom: "0",
        right: theme => theme.lang === "ar" ? 0 : "unset",
        left: theme => theme.lang === "ar" ? "unset" : 0,
        width: 301,
        height: 70,
        display: "flex",
        background: theme => theme.name === "dark" ? theme.backgroundNoElevation : theme.tint,
        boxSizing: "border-box",
        clipPath: theme => theme.lang === "ar" ? "polygon(10% 0, 100% 0, 100% 100%, 0% 100%)" : "polygon(0 0, 90% 0, 100% 100%, 0% 100%)",
        paddingTop: 16,
        paddingBottom: 16,
        padding: (theme => "16" + (theme.lang === "ar" ? "0" : "16") + "16" + (theme.lang === "ar" ? "16" : "0")),
        justifyContent: "space-evenly"
    }
});


function SocialMediaActions(props) {


    // todo add real links
    const socialMedia = [
        {
            name: "Twitter",
            link: "https://www.twitter.com",
            state: useState(false),
            image: twitter,
            imageHover: twitterHover
        },
        {
            name: "Instagram",
            link: "https://www.instagram.com",
            state: useState(false),
            image: insta,
            imageHover: instaHover
        },
        {
            name: "Facebook",
            link: "https://www.facebook.com",
            state: useState(false),
            image: facebook,
            imageHover: facebookHover
        }
    ]

    function toggleHover(stateObject) {
        stateObject[1](!stateObject[0])
    }

    const {theme, toggleTheme} = useContext(ThemeContext)
    const {lang, toggleLang} = useContext(LangContext)


    const classes = useStyle({...theme, lang});


    return (
        <div className={classes.SocialMediaActions}>

            {socialMedia.map((item) => {
                return (<a href={item.link}>
                    <img src={item.state[0] ? item.imageHover : item.image} alt={item.name + "icon"} onMouseEnter={() => toggleHover(item.state)} onMouseLeave={() => toggleHover(item.state)}/>
                </a>)
            })}

        </div>
    );
}

export default SocialMediaActions;
