import React from 'react';

function Contact(props) {
    return (
        <div className="Contact">

        </div>
    );
}

export default Contact;
