import React, {useContext} from 'react';
import {ThemeContext} from "./context/ThemeContext";
import {LangContext} from "./context/LangContext";
import {makeStyles} from "@material-ui/styles";
import whiteLogo from "./assets/svgs/white-logo.svg"

const useStyle = makeStyles({
    "footer": {
        background: theme => theme.name === "dark" ? theme.backgroundElevation : theme.secDarkPurple,
        paddingTop: 140,
        paddingBottom: 140,
        color: theme => theme.onPrimary,
        position: "relative"
    },

    "content": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-between"
    },

    "row": {

        padding: 40,
        display: "flex",
        flexDirection: "column",

        "& h4": {
            font: "normal 500 normal 20px/24px",
            marginTop: 0
        },

        "& a": {
            font: "normal 400 normal 16px/20px"
        }

    },

    "footerfooter": {
        height: 60,
        width: "100%",
        background: theme => theme.name === "dark" ? theme.backgroundNoElevation : theme.secLightBlue,
        position: "absolute",
        bottom: 0
    },

    "footerfootercontent": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",

        "& a":{
            margin: 25
        }
    }

});


function Footer(props) {

    const {theme, toggleTheme} = useContext(ThemeContext)
    const {lang, toggleLang} = useContext(LangContext)

    const classes = useStyle({...theme, lang});


    return (
        <div className={classes.footer}>

            <div className={classes.content + " container"}>
                <div className={classes.row}>
                    <img alt="Company logo" src={whiteLogo}/>
                    <span>
                    Copyrights © for WHELAM TECH <br/>
                    all rights reserved
                </span>
                </div>

                <div className={classes.row}>
                    <h4>
                        About Us
                    </h4>

                    <a>about us</a>
                    <a>who we are</a>
                    <a>our goals</a>
                    <a>contact us</a>
                </div>

                <div className={classes.row}>
                    <h4>
                        About Us
                    </h4>

                    <a>about us</a>
                    <a>who we are</a>
                    <a>our goals</a>
                    <a>contact us</a>
                </div>

                <div className={classes.row}>
                    <h4>
                        About Us
                    </h4>

                    <a>about us</a>
                    <a>who we are</a>
                    <a>our goals</a>
                    <a>contact us</a>
                </div>


            </div>

            <div className={classes.footerfooter}>
                <div className={classes.footerfootercontent + " container"}>

                    <span>
                       Designed and Developed by © WHELAM TECH
                    </span>

                    <div>
                        <a>Terms</a>
                        <a>Privacy</a>
                        <a>Help and FAQS</a>
                    </div>

                </div>
            </div>

        </div>
    );
}

export default Footer;
