import React from 'react';
import {makeStyles} from "@material-ui/styles";

const useStyle = makeStyles({
    ServiceIcon: {
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 10,

        '& img':{
            width: "100%",
            height: "100%",
            filter: "brightness(0)invert(1)"
        }
    }
})


function ServiceIcon(props) {

    const classes = useStyle()

    var backgroundStyle = {
        background: `linear-gradient(45deg, ${props.color1} 0%, ${props.color2} 100%)`,
        width: props.width,
        height: props.height
    };

    return (
        <div className={classes.ServiceIcon} style={backgroundStyle}>

            <img src={props.pic} alt=""/>


        </div>
    );
}

export default ServiceIcon;
