import React, {useContext} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../../context/ThemeContext";
import {LangContext} from "../../context/LangContext";
import {parseBrandName} from "../helper";
import {colorFromTheme} from "../helper";

const useStyle = makeStyles({

    Slide: {
        display: "flex",
        paddingTop: 100,
        paddingBottom: 100,
        alignItems: "center",

        color: theme => theme.name === "dark" ? theme.onPrimary : theme.main,


        "& > *": {
            width: "50%"
        },


    },

    '@media screen and (max-width: 1200px)': {
        Slide: {
            justifyContent: "center",

            "& > *": {
                width: "80%",
            },

            "& > img": {
                position: "absolute",
                opacity: 0.1,
                maxHeight: "60%",
                objectFit: "cover"
            },
        },
    },

    '@media screen and (max-width: 800px)': {
        iconsContainer: {
            flexDirection: ["column", "!important"]
        },

        Slide: {
            justifyContent: "center",

            "& > *": {
                width: "80%",
            },

            "& > img": {
                display: "none"
            },



        },
    },

    textContainer: {
        display: "flex",
        flexDirection: "column",
        paddingLeft: 36
    },

    iconsContainer: {
        display: "flex",
        justifyContent: "space-evenly",
    },

    iconHolder: {

        display: "flex",
        alignItems: "center",

        "& h4":{
            fontSize: 24,
            marginLeft: theme => theme.lang === "ar" ?  "unset" : 10,
            marginRight: theme => theme.lang === "ar" ?  10 : "unset",
        },

    },

    iconColorHolder:{
        width: 75,
        height: 75,
        display: "flex",
        alignItems: "center",
        justifyContent:"center",
        borderRadius: 100,

        "& img": {
            width: 45,
            padding: 6,
            margin: 8,
            filter: "brightness(0)invert(1)",
        }
    }

});


function Slide(props) {

    const {theme, toggleTheme} = useContext(ThemeContext)
    const {lang, toggleLang} = useContext(LangContext)


    const classes = useStyle({...theme, lang});


    return (
        <div className={classes.Slide + " container"}>
            <img alt={""} style={{order: props.side === 0 ? 1 : 2}} src={theme.name === "dark" ? props.slide.img_url_dark : props.slide.img_url_light}/>
            <div className={classes.textContainer} style={{order: props.side === 0 ? 2 : 1}}>
                <h1>
                    {lang === "ar" ? props.slide.header_ar : props.slide.header_en}
                </h1>

                <p>
                    {lang === "ar" ? props.slide.paragraph_ar : props.slide.paragraph_en}
                </p>

                <div className={classes.iconsContainer}>
                    <div className={classes.iconHolder}>

                        <div className={classes.iconColorHolder} style={{background: `linear-gradient(45deg, ${colorFromTheme(1, props.slide.icon1_color, theme)} 0%, ${colorFromTheme(2, props.slide.icon1_color, theme)} 100%)`}}>
                            <img src={props.slide.icon1_url}  alt={""}/>
                        </div>

                        <h4 dangerouslySetInnerHTML={lang === "ar" ? {__html: parseBrandName(props.slide.icon1_text_ar)} : {__html: parseBrandName(props.slide.icon1_text_en)}}/>

                    </div>

                    {props.slide.icon2_text_en != null ?

                        <div className={classes.iconHolder}>

                            <div className={classes.iconColorHolder} style={{background: `linear-gradient(45deg, ${colorFromTheme(1, props.slide.icon2_color, theme)} 0%, ${colorFromTheme(2, props.slide.icon2_color, theme)} 100%)`}}>
                                <img src={props.slide.icon2_url}  alt={""}/>
                            </div>


                            <h4 dangerouslySetInnerHTML={lang === "ar" ? {__html: parseBrandName(props.slide.icon2_text_ar)} : {__html: parseBrandName(props.slide.icon2_text_en)}}/>

                        </div>

                        : ""}
                </div>

            </div>
        </div>
    );
}

export default Slide;
