import React, {useContext, useState} from 'react';
import {NavLink} from "react-router-dom";
import {makeStyles} from '@material-ui/styles';
import {ThemeContext} from "./context/ThemeContext";
import {LangContext} from "./context/LangContext";
import Lottie from 'react-lottie-player'
import darkModeOn from './assets/lottie/darkModeOn.json'
import darkModeOff from './assets/lottie/darkModeOff.json'
import logoLightTheme from "./assets/svgs/coloredLogo.svg"
import logoDarkTheme from "./assets/svgs/white-logo.svg"
import MediaQuery from "react-responsive";
import {PrimaryButton} from "./StyledComponents";

const useStyle = makeStyles({
    Header: {
        height: theme => theme.headerHeight,
        width: "100%",
        background: theme => theme.name === "dark" ? theme.backgroundElevation : theme.onPrimary,
        boxShadow: theme => theme.headerShadow
    },

    headerContent: {
        height: theme => theme.headerHeight,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    headerLogo: {
        '& img': {
            height: 35
        }
    },

    linkContainer: {
        display: "flex",
        alignItems: "center",

        '& > *': {
            color: theme => theme.name === "dark" ? theme.onPrimary : theme.main,
            marginInlineEnd: 30
        }


    },

    lottieHolder: {
        cursor: "pointer",
        width: "120px",
    },

    normalLink: {
        transition: "color 400ms ease-out",

        '&:hover': {
            color: theme => theme.primaryPurple
        }
    }
});


function Header(props) {

    const {theme, toggleTheme} = useContext(ThemeContext)
    const {lang, toggleLang} = useContext(LangContext)

    const [isPlaying, setIsPlaying] = useState(false)


    const classes = useStyle({...theme, lang});

    let text;

    if (lang === "ar") {
        document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");

        text = {
            home: "الرئيسيه",
            services: "الخدمات",
            pricing: "التسعير",
            portfolio: "معرضنا",
            about: "من نحن",
            contact: "تواصل معنا",
            changeThemeToolTip: `غير إلي ${theme.name === "dark" ? "مظهر فاتح" : "مظهر داكن"}`,
        }

    } else {
        document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");

        text = {
            home: "Home",
            services: "Services",
            pricing: "pricing",
            portfolio: "Portfolio",
            about: "About",
            contact: "Contact us",
            changeThemeToolTip: `Switch to ${theme.name === "dark" ? "Light" : "Dark"} Theme`,
        }
    }

    return (

        <div className={classes.Header}>
            <MediaQuery minDeviceWidth={800}>


                <div className={`container ${classes.headerContent}`}>
                    <div>
                        <NavLink to={"/"} className={classes.headerLogo}>
                            <img src={theme.name === "dark" ? logoDarkTheme : logoLightTheme} alt=""/>
                        </NavLink>
                    </div>

                    <div className={classes.linkContainer}>
                        <NavLink to={"/"} className={classes.normalLink}>
                            {text.home}
                        </NavLink>

                        <a href={"#our-services"} className={classes.normalLink}>
                            {text.services}
                        </a>


                        <a href={"#our-pricing"} className={classes.normalLink}>
                            {text.pricing}
                        </a>

                        <a href={"#"} className={classes.normalLink}>
                            {text.portfolio}
                        </a>

                        <NavLink to={"/about"} className={classes.normalLink}>
                            {text.about}
                        </NavLink>

                        <NavLink to={"/contact"} className={classes.contact}>
                            <PrimaryButton theme={theme} lang={lang}>{text.contact}</PrimaryButton>
                        </NavLink>

                        <div onClick={toggleLang} style={{cursor: "pointer"}} className={classes.normalLink}>
                            {lang.toUpperCase()}
                        </div>

                        <div className={classes.lottieHolder} onClick={() => {
                            setIsPlaying(true);
                        }}>
                            {
                                <Lottie title={text.changeThemeToolTip} animationData={theme.name === "light" ? darkModeOff : darkModeOn} onComplete={() => {
                                    if (isPlaying) {
                                        toggleTheme();
                                        setIsPlaying(false);
                                    }
                                }} play={isPlaying} speed={5} loop={false} style={{height: 60}}/>
                            }
                        </div>
                    </div>
                </div>

            </MediaQuery>
        </div>
    );
}

export default Header;

