import React, {useContext} from 'react';
import Header from "./Header";
import {Route, Switch} from "react-router-dom";
import Home from "./home/Home";
import About from "./About";
import Contact from "./Contact";
import Footer from "./Footer";
import {makeStyles} from '@material-ui/styles';
import {ThemeContext} from "./context/ThemeContext";
import {root} from "./Constants";


const globalStyles = makeStyles({

    '@global': {
        '@import': 'url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap)',

        'html':{
            scrollBehavior: "smooth"
        },

        'a': {
            textDecoration: "none"
        },
        '.container': {
            width: "100%",
            paddingRight: "0.5rem",
            paddingLeft: "0.5rem",
            marginRight: "auto",
            marginLeft: "auto",
            transition: "background 300ms ease-out",
            background: theme => theme.color
        },


        '.color':{
            fontWeight: 600,
            letterSpacing: 1.8,
        },
        '.color1':{
            color: theme => theme.primaryPurple
        },
        '.color2':{
            color: theme => theme.primary
        },


        '@media (min-width: 576px)': {
            '.container': {
                maxWidth: "540px"
            }
        },

        '@media (min-width: 768px)': {
            ".container": {
                maxWidth: "720px"
            }
        },

        '@media (min-width: 992px)': {
            ".container": {
                maxWidth: "960px"
            }
        },

        '@media (min-width: 1200px)': {
            ".container": {
                maxWidth: "1140px"
            }
        },

        '@media (min-width: 1400px)': {
            ".container": {
                maxWidth: "1320px"
            }
        },

        'body': {
            padding: "0",
            margin: "0",
            fontFamily: "'Poppins', sans-serif",
        },
    }
});


function App(props) {

    const {theme, toggleTheme} = useContext(ThemeContext)

    globalStyles(theme);

    return (
        <div>
            <Header/>

            <Switch>

                <Route exact path={`${root}/`} component={Home}/>
                <Route exact path={`${root}/about`} component={About}/>
                <Route exact path={`${root}/contact`} component={Contact}/>

            </Switch>

            <Footer/>

        </div>
    );
}

export default App;
