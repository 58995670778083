import React,{createContext, useState} from "react";

const lightTheme = {
    name: "light",

    //lengths
    headerHeight: "75px",

    //spacingUnit
    spacingUnit: 2,

    //colors
    secLightBlueDark: "#4358CC",
    secDarkBlue: "#132066",
    secDarkPurple: "#2B0066",
    facebook: "#4267B2",
    twitter: "#1DA1F2",
    tone: "#8B97AE",
    accent: "#FFC400",
    shade: "#212C42",
    main: "#192233",
    tint: "#E4E6F2",
    onPrimary: "#FCFDFF",
    white: "#ffffff",
    secLightBlue: "#536DFE",
    primary: "#243BBF",
    primaryPurple: "#5000bf",
    shadow: "#D3D6E6",
    headerShadow: "0px 5px 10px #00000014",
}

const darkTheme = {
    name: "dark",

    //lengths
    headerHeight: "75px",

    //spacingUnit
    spacingUnit: 2,

    //colors
    secLightBlueDark: "#8C9EFF",
    secDarkBlue: "#132066",
    secDarkPurple: "#6e3dc8",
    facebook: "#4267B2",
    twitter: "#1DA1F2",
    tone: "#8B97AE",
    accent: "#FFC400",
    shade: "#212C42",
    main: "#192233",
    tint: "#E4E6F2",
    onPrimary: "#FCFDFF",
    secLightBlue: "#536DFE",
    primary: "#6781f7",
    primaryPurple: "#6e3dc8",
    shadow: "#D3D6E6",
    headerShadow: "0px 5px 10px #00000014;",

    backgroundElevation: "#212C42",
    backgroundNoElevation: "#192233",
}


export const ThemeContext = createContext();



export function ThemeProvider(props){

    const [theme, setTheme] = useState(localStorage.getItem('isLight') === "1" ? lightTheme : darkTheme);

    function toggleTheme(){
        if (theme === lightTheme){
            setTheme(darkTheme);
            localStorage.setItem("isLight", "")
        }else{
            setTheme(lightTheme);
            localStorage.setItem("isLight", "1")
        }
    }



    return (
        <ThemeContext.Provider value={{theme, toggleTheme}}>
            {props.children}
        </ThemeContext.Provider>
    )

}
