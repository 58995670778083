import {styled} from "@material-ui/styles";

export const PrimaryButton = styled('button')(({theme}) => ({
    borderRadius: 5,
    padding: "10px 25px",
    font: "normal normal 500 16px/25px Poppins",
    color: theme.onPrimary,
    background: theme.name === "dark" ? theme.primary : theme.secLightBlue,
    textTransform: "uppercase",
    border: "none",
    transition: "all 200ms linear",
    cursor: "pointer",

    "&:hover":{
        background: `linear-gradient(107deg, ${theme.primary} 0%, ${theme.secLightBlue} 100%) 0% 0% no-repeat padding-box`
    }

}))

export const SecondaryButton = styled('button')(({theme}) => ({
    borderRadius: 5,
    padding: "10px 25px",
    font: "normal normal 500 16px/25px Poppins",
    textTransform: "uppercase",
    color: theme.onPrimary,
    background: "transparent",
    border: `3px solid ${theme.onPrimary}`,
    cursor: "pointer",
    transition: "all 200ms linear",

    "&:hover":{
        background: theme.onPrimary,
        color: theme.primary
    }

}))








