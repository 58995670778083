export function parseBrandName(text) {

    let finalText = text;


    for (let i = 0; i < text.length; i++) {
        if (text[i] === "#") {
            for (let j = i + 1; j < text.length; j++) {
                if (text[j] === "#") {
                    finalText = finalText.substr(0, i) + `<span class="color color1">${finalText.substr(i + 1, j - (i + 1))} </span>` + finalText.substr(j + 1);
                }
            }
        }
    }

    for (let i = 0; i < finalText.length; i++) {
        if (finalText[i] === "$") {
            for (let j = i + 1; j < finalText.length; j++) {
                if (finalText[j] === "$") {
                    finalText = finalText.substr(0, i) + `<span class="color color2">${finalText.substr(i + 1, j - (i + 1))} </span>` + finalText.substr(j + 1);
                }
            }
        }
    }

    return finalText;

}

export function colorFromTheme(index, gradientType, theme) {
    if (index === 1){

        switch (gradientType){
            case "1":
                return theme.secDarkPurple
            case "2":
                return theme.primaryPurple
            case "3":
                return theme.primary
            case "4":
                return theme.secDarkBlue

        }

    }else if (index === 2){

        switch (gradientType){
            case "1":
                return theme.primaryPurple
            case "2":
                return theme.primary
            case "3":
                return theme.primaryPurple
            case "4":
                return theme.primary
            default:

                break
        }

    }
}
