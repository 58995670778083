import React, {useState, useContext, useEffect} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../../context/ThemeContext";
import {LangContext} from "../../context/LangContext";
import ServiceIcon from "../ServiceIcon";
import quotes from "../../assets/svgs/quotes.svg"
import {colorFromTheme, parseBrandName} from "../helper"


const useStyle = makeStyles({

    QuotesSection: {
        width: 620,
        height: 410,
        position: "relative",
        left: theme => theme.lang === "ar" ? "unset" : -50,
        right: theme => theme.lang === "en" ? "unset" : -50,
        overflow: "hidden"
    },

    '@media screen and (max-width: 1200px)': {
        QuotesSection: {
            marginTop: 64,
            marginBottom: 64,

        },
    },

    '@media screen and (max-width: 800px)': {
        QuotesSection: {
            left: [-20, "!important"]
        },
    },

    counter: {
        position: "absolute",
        zIndex: [4, '!important'],
        top: 125,
        display: "flex",
    },

    currentCounter: {
        color: theme => theme.primary,
        font: "normal normal 500 24px/35px Poppins",
        position: "absolute",
        top: 125,
        left: theme => theme.lang === "ar" ? "unset" : 0,
        right: theme => theme.lang === "en" ? "unset" : 0,
        width: 50,
        textAlign: "center",
        display: "block",
        zIndex: [5, "!important"],

        '&::before': {
            background: theme => theme.primary,
            position: "absolute",
            content: '""',
            width: "100%",
            height: 2,
            top: 30,
            left: 0,
            right: 0,
        }

    },

    totalCounter: {
        color: theme => theme.onPrimary,
        font: "normal normal 500 24px/35px Poppins",
        position: "relative",
        width: 50,
        textAlign: "center",
        display: "block",


        '&::before': {
            background: theme => theme.onPrimary,
            position: "absolute",
            content: '""',
            width: "100%",
            height: 2,
            top: 30,
            left: 0,
            right: 0,
        }

    },

    wrapper: {
        width: 570,
        height: 410,
        position: "relative",
        left: theme => theme.lang === "ar" ? "unset" : 50,
        right: theme => theme.lang === "en" ? "unset" : 50,
        overflow: "hidden"
    },

    serviceSlide: {
        width: 335,
        top: 0,
    },

    serviceNameDiv: {
        height: 160,
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "start",
        backgroundColor: theme => theme.name === "dark" ? theme.backgroundNoElevation : theme.onPrimary,
        borderTopLeftRadius: theme => theme.lang === "ar" ? "unset" : 20,
        borderTopRightRadius: theme => theme.lang === "en" ? "unset" : 20,
        left: theme => theme.lang === "ar" ? "unset" : 0,
        right: theme => theme.lang === "en" ? "unset" : 0,
        position: "absolute",
        padding: 20,
        boxSizing: "border-box",
        top: 0,
    },

    serviceWrapper: {
        height: 95,
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
    },

    serviceName: {
        marginLeft: theme => theme.lang === "ar" ? "unset" : 20,
        marginRight: theme => theme.lang === "ar" ? 20 : "unset",
        font: "normal normal 500 24px/32px Poppins",
        letterSpacing: 2.6,
        color: theme => theme.name === "dark" ? theme.onPrimary : theme.main,
        margin: 0,
    },

    quoteSlide: {
        width: 442,
        minHeight: 266,
        position: "absolute",
        backgroundSize: "cover",
        top: 115,
        left: theme => theme.lang === "en" ? 130 : "unset",
        right: theme => theme.lang === "ar" ? 130 : "unset",
        borderRadius: theme => theme.lang === "ar" ? "20px 0px 20px 20px" : "0px 20px 20px 20px",
        overflow: "hidden",

        '&::before': {
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.45)",

        },

        '& img': {
            position: "absolute",
            zIndex: -1,
            maxWidth: "100%",
            maxHeight: "100%",
            objectFit: "cover",


        }
    },

    quoteSlideWrapper: {
        marginTop: 30,
        padding: 20,
        color: theme => theme.onPrimary,
        position: "relative",

        '& h3': {
            textAlign: theme => theme.lang === "ar" ? "left" : "right",
        }

    },

    loader: {
        position: "absolute",
        bottom: 20,
        height: 3,
        width: 100,
        background: theme => `${theme.onPrimary + "7f"} `,
        right: theme => theme.lang === "ar" ? "unset" : 45,
        left: theme => theme.lang === "ar" ? 45 : "unset",
        borderRadius: 100,


        '& div': {
            position: "absolute",
            display: "block",
            height: 3,
            width: 0,
            transitionDelay: "2s",
            background: theme => theme.onPrimary,
            right: theme => theme.lang === "ar" ? 0 : "unset",
            left: theme => theme.lang === "ar" ? "unset" : 0,
            borderRadius: 100,
        }

    },


})

function QuotesSection(props) {

    const [width, setWidth] = useState(0)
    const [currentService, setCurrentService] = useState(0)

    const {theme, toggleTheme} = useContext(ThemeContext)
    const {lang, toggleLang} = useContext(LangContext)


    const classes = useStyle({...theme, lang});


    useEffect(() => {
        setTimeout(() => {
            if (width === 0) {

                setWidth(100)

            } else {
                setWidth(0)
                if (currentService === props.services.length - 1) {
                    setCurrentService(0)
                } else {
                    setCurrentService(currentService + 1);
                }
            }
        }, 4000)
    }, [width])

    return (
        <div className={classes.QuotesSection}>
            <div className={classes.counter}>
                <span className={classes.totalCounter}>
                    {props.services.length.toString().padStart(2, "0")}
                </span>
            </div>


            <div className={classes.wrapper}>
                <div className={classes.serviceSlide}>
                    <span className={classes.currentCounter} style={{
                        left: `-${lang === "ar" ? "unset" : width * 0.5}px`,
                        right: `-${lang === "en" ? "unset" : width * 0.5}px`,
                        transition: `${lang === "en" ? "left 1s ease-in-out" : "right 1s ease-in-out"}`,
                        transitionDelay: width === 0 ? "400ms" : "3s"
                    }}> {(currentService + 1).toString().padStart(2, "0")} </span>

                    <div className={classes.serviceNameDiv} style={{
                        left: `-${lang === "ar" ? "unset" : width}%`,
                        right: `-${lang === "en" ? "unset" : width}%`,
                        transition: `${lang === "en" ? "left 1s ease-in-out" : "right 1s ease-in-out"}`,
                        transitionDelay: width === 0 ? "400ms" : "3s"
                    }}>

                        <div className={classes.serviceWrapper}>

                            <ServiceIcon pic={props.services[currentService].icon_url} width={"75px"} height={"75px"} color1={colorFromTheme(1, props.services[currentService].gradient_type, theme)} color2={colorFromTheme(2, props.services[currentService].gradient_type, theme)}/>

                            <h4 className={classes.serviceName} dangerouslySetInnerHTML={lang === "ar" ? {__html: parseBrandName(props.services[currentService].name_ar)} : {__html: parseBrandName(props.services[currentService].name_en)}}/>

                        </div>
                    </div>
                </div>


                <div className={classes.quoteSlide} style={{
                    backgroundImage: `url(${props.services[currentService].image_url})`,
                    left: `${lang === "ar" ? "unset" : (570 - 440) + width * 5.70 * (width / 100)}px`,
                    right: `${lang === "en" ? "unset" : (570 - 440) + width * 5.70 * (width / 100)}px`,
                    transition: "all 1s ease-in-out",
                    transitionDelay: width === 0 ? "400ms" : "3s"
                }}>


                    <div className={classes.quoteSlideWrapper}>
                        <img src={quotes} alt={""} style={{position: "absolute",
                            width: 20,
                            top: lang === "ar" ? "unset" : 32,
                            left: lang === "ar" ? 16 : "unset",
                            bottom: lang === "ar" ? 80 : "unset"
                        }}/>

                        <p style={{marginRight: 25, marginLeft: 25}}>

                            {lang === "ar" ? props.services[currentService].quote_ar : props.services[currentService].quote_en}

                        </p>

                        <img src={quotes} alt={""} style={{
                            position: "absolute",
                            transform: "rotate(180deg)",
                            width: 20,
                            top: lang === "ar" ? 32 : "unset",
                            right: lang === "ar" ? 16 : 16,
                            left: lang === "ar" ? 16 : "unset",
                            bottom: lang === "ar" ? "unset" : 80
                        }}/>

                        <h3>{lang === "ar" ? props.services[currentService].author_ar : props.services[currentService].author_en}</h3>


                    </div>

                    <div className={classes.loader}>
                        <div style={{
                            width: `${width}px`,
                            transition: width === 0 ? "none" : "width 3s ease-in-out"
                        }}>

                        </div>
                    </div>

                </div>

            </div>


        </div>
    );
}

export default QuotesSection;
