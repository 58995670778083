import React, {useContext} from 'react';
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../../context/ThemeContext";
import {LangContext} from "../../context/LangContext";
import {PrimaryButton} from "../../StyledComponents"

const useStyle = makeStyles({
    pricing: {
        height: 800,
        width: 360,
        boxShadow: "2px 5px 12px #000001f",
        backgroundColor: theme => theme.name === "dark" ? theme.backgroundElevation : theme.onPrimary,
        borderRadius: 15,
        color: theme => theme.name === "dark" ? theme.onPrimary : theme.main,


        "& h1": {
            font: "normal 600 normal 32px/25px",
            color: theme => theme.primaryPurple,
            textAlign: "center"
        },

        "& h2": {
            paddingTop: 50,
            textAlign: "center"
        },

        "& h3": {
            font: "normal 600 normal 60px/60px Poppins",
            padding: 0,
            margin: 0,
            textAlign: "center",
            color: theme => theme.primaryPurple
        },

        "& h4": {
            font: "normal 600 normal 16px/25px",
            textAlign: "center",
            paddingLeft: 26,
            paddingRight: 26,
        },

        "& h5": {
            font: "normal 400 normal 40px/60px Poppins",
            padding: 0,
            margin: 0,
            textAlign: "center",
            color: theme => theme.primary
        },

        "& pre": {
            textAlign: "center",
            font: "normal 500 normal 12px/12px Poppins",
        },

        "& span": {
            display: "block",
            textAlign: "center",
            font: "normal 500 normal 16px/25px Poppins",
            color: theme => theme.name === "dark" ? theme.shade : theme.tint
        },

        "& button": {
            display: "block",
            textAlign: "center",
            margin: "0 auto"
        }

    }
});


function Pricing(props) {

    const {theme, toggleTheme} = useContext(ThemeContext)
    const {lang, toggleLang} = useContext(LangContext)

    const classes = useStyle({...theme, lang});

    return (
        <div className={classes.pricing}>
            <h1>
                {lang === "ar" ? props.pricing.name_ar : props.pricing.name_en}
            </h1>

            <h4>
                {lang === "ar" ? props.pricing.sub_name_ar : props.pricing.sub_name_en}
            </h4>

            <h2>
                What is included
            </h2>

            <pre>
                {lang === "ar" ? props.pricing.what_is_included_ar : props.pricing.what_is_included_en}
            </pre>

            <span>
                {lang === "ar" ? props.pricing.time_ar : props.pricing.time_en} of work maximum
            </span>

            <span>
                {lang === "ar" ? props.pricing.support_ar : props.pricing.support_en} free support
            </span>

            {
                props.pricing.price_dollar === "0" ? <div style={{textAlign: "center", padding: 40}}><b>Note:</b> In this package, the price is determined directly with the customer according to the requirements and conditions</div> : <div><h2>Starting from</h2><h3>{props.pricing.price_dollar}$</h3><h5>{props.pricing.price_egp} EGP</h5></div>
            }


            <PrimaryButton theme={{...theme, lang}}>Contact us</PrimaryButton>

        </div>
    );
}

export default Pricing;
