import React, {useContext, useState, useEffect, Component} from 'react';
import SocialMediaActions from "./banner-section/SocialMediaActions";
import {makeStyles} from "@material-ui/styles";
import {ThemeContext} from "../context/ThemeContext";
import {LangContext} from "../context/LangContext";
import decor1 from "../assets/svgs/banner-section-decore.svg"
import TextSection from "./banner-section/TextSection";
import QuotesSection from "./banner-section/QuotesSection";
import MediaQuery from 'react-responsive'
import {servicePath} from "../Constants";
import ServiceCard from "./our-services-section/ServiceCard";
import topLeftStyle from "../assets/svgs/top-left-svg.svg"
import bottomRightStyle from "../assets/svgs/bottom-right-svg.svg"
import Slide from "./slides/Slide";
import Carousel from 'react-elastic-carousel'
import Pricing from "./pricing/Pricing";

const useStyle = makeStyles({

    "HomeContainer": {
        background: theme => theme.name === "dark" ? theme.backgroundNoElevation : theme.tint
    },

    /** banner section */

    "BannerSection": {
        position: "relative",
        minHeight: theme => `calc(100vh - ${theme.headerHeight})`,
        background: theme => theme.name === "dark" ? theme.backgroundElevation : `linear-gradient(308deg, ${theme.primaryPurple} 0%, ${theme.primary} 100%) 0 0 no-repeat padding-box`,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,

        '& *': {
            zIndex: 2
        }
    },

    "bannerSectionDecore": {
        position: "absolute",
        bottom: 0,
        left: 0,
        opacity: 0.12,
        zIndex: 0,
        maxHeight: "86%"
    },

    "bannerSectionContainer": {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center"
    },

    '@media screen and (max-width: 720px)': {
        "bannerSectionContainer": {
            marginBottom: "50px"
        },
    },


    /** our Services section */

    "OurServicesSection": {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",

        '& h1': {
            font: "normal normal normal 40px/60px Poppins",
            color: theme => theme.name === "dark" ? theme.onPrimary : theme.primary,
            textAlign: "center",

            '& span': {
                font: " normal normal 600 40px/60px Poppins",
                color: theme => theme.name === "dark" ? theme.onPrimary : theme.primaryPurple,
            }
        }
    },

    serviceHolder: {
        position: "relative",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
    },

    topLeftDecore: {
        position: "absolute",
        top: 0,
        left: theme => theme.lang === "ar" ? "unset" : 0,
        right: theme => theme.lang === "ar" ? 0 : "unset",
        opacity: 0.15,
        filter: theme => theme.name === "dark" ? "brightness(0%)invert(1)" : "unset"
    },

    bottomRightDecore: {
        position: "absolute",
        bottom: 0,
        right: theme => theme.lang === "ar" ? "unset" : 0,
        left: theme => theme.lang === "ar" ? 0 : "unset",
        transform: theme => theme.lang === "ar" ? "rotateY(180deg)" : "unset",
        opacity: 0.15,
        filter: theme => theme.name === "dark" ? "brightness(0%)invert(1)" : "unset"
    },

    /** slides section */

    slideHolder: {
        display: "flex",
        flexDirection: "column"
    },

    /** pricing section */

    Pricing: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: 200,
        paddingBottom: 200,
    }


});

function Home(props) {


    const {theme, toggleTheme} = useContext(ThemeContext)
    const {lang, toggleLang} = useContext(LangContext)

    const [services, setServices] = useState([])
    const [slides, setSlides] = useState([])
    const [pricing, setPricing] = useState([])
    const [langCounter, setLangCounter] = useState(0)


    const classes = useStyle({...theme, lang});


    function fetchServices() {
        const data = new FormData();

        fetch(`${servicePath}index.php/get-admin-services`, {
            method: 'POST',
            body: data,
            headers:
                {
                    "Accept": "application/json"
                }
        }).then(function (res) {
            return res.json();
        }).then(function (data) {
            setServices(data);
            fetchSlides()
        }).catch(function (error) {
            console.log(error)
            console.log("error")
        });
    }

    function fetchSlides() {
        const data = new FormData();

        fetch(`${servicePath}index.php/get-admin-slides`, {
            method: 'POST',
            body: data,
            headers:
                {
                    "Accept": "application/json"
                }
        }).then(function (res) {
            return res.json();
        }).then(function (data) {
            setSlides(data);
            fetchPricing();
        }).catch(function (error) {
            console.log(error)
            console.log("error")
        });
    }

    function fetchPricing() {
        const data = new FormData();

        fetch(`${servicePath}index.php/get-admin-pricing`, {
            method: 'POST',
            body: data,
            headers:
                {
                    "Accept": "application/json"
                }
        }).then(function (res) {
            return res.json();
        }).then(function (data) {
            setPricing(data);
        }).catch(function (error) {
            console.log(error)
            console.log("error")
        });
    }


    useEffect(() => {
        fetchServices();
    }, [])

    useEffect(() => {
        setLangCounter(lang+1)
    }, [lang])

    return (
        <div className={classes.HomeContainer}>

            <div className={classes.BannerSection}>
                <MediaQuery minDeviceWidth={720}>
                    <img className={classes.bannerSectionDecore} src={decor1} alt={""}/>
                </MediaQuery>

                <div className={classes.bannerSectionContainer}>
                    <TextSection/>

                    <MediaQuery minDeviceWidth={720}>


                        {services[0] ? <QuotesSection key={langCounter} services={services} lang={lang} /> : <div/>}

                    </MediaQuery>
                </div>


                <SocialMediaActions/>
            </div>

            <div className={classes.OurServicesSection}>

                <h1 id={"our-services"}>
                    Our <span>Services</span>
                </h1>

                <div className={classes.serviceHolder}>

                    <img className={classes.topLeftDecore} src={topLeftStyle}/>

                    {services.map((service) => {
                        return <ServiceCard service={service}/>
                    })}

                    <img className={classes.bottomRightDecore} src={bottomRightStyle}/>

                </div>

            </div>

            <div className={classes.slideHolder}>

                {slides.map((slide, index) => {
                    return <Slide slide={slide} side={index % 2}/>
                })}

            </div>

            <div className={classes.Pricing + " container"}>

                <h1 id={"our-pricing"}>
                    <span>Pricing</span>
                </h1>

                <Carousel itemPadding={[0, 20]} breakPoints={[
                    {width: 1, itemsToShow: 1},
                    {width: 800, itemsToShow: 2},
                    {width: 1200, itemsToShow: 3},
                ]}>

                    {
                        pricing.map((price) => {
                            return <Pricing pricing={price}/>
                        })
                    }

                </Carousel>

            </div>


        </div>
    );
}

export default Home;
